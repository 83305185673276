import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { Button, Carousel, Avatar } from "antd"

import Layout from "../layouts"
import { MEDIA_INTRO } from "../utils/constant"

import "./index.css"

export default () => {
  useEffect(() => {
    if (typeof window === "undefined" || typeof document === 'undefined') {
      return;
    }
    const transitionLayer = document.getElementsByClassName(
        "cd-transition-layer"
      )[0],
      transitionBackground = document.getElementsByClassName("bg-layer")[0]

    const frameProportion = 1.78, //png frame aspect ratio
      frames = 25 //number of png frames

    //set transitionBackground dimentions
    setLayerDimensions()

    transitionLayer.className += " visible opening"

    setTimeout(function() {
      hideInk()
    }, 600)

    const hideInk = function() {
      transitionLayer.className += " closing"

      setTimeout(function() {
        transitionLayer.className = transitionLayer.className
          .replace("closing", "")
          .replace("opening", "")
          .replace("visible", "")

        const mainContent = document.getElementsByClassName(
          "mdnice-homepage-cd-main-content"
        )[0]
        mainContent.style.display = "table"
      }, 900)
    }

    function setLayerDimensions() {
      const windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      let layerHeight
      let layerWidth

      if (windowWidth / windowHeight > frameProportion) {
        layerWidth = windowWidth
        layerHeight = layerWidth / frameProportion
      } else {
        layerHeight = windowHeight * 1.2
        layerWidth = layerHeight * frameProportion
      }

      transitionBackground.style.width = layerWidth * frames + "px"
      transitionBackground.style.height = layerHeight + "px"
    }
  }, [])

  return (
    <Layout isSpacerDisplay={false} title="墨滴软件-mdnice">
      <div className="mdnice-homepage-container">
        <div className="mdnice-homepage-heading">
          <main
            className="mdnice-homepage-cd-main-content"
            style={{ display: "none" }}
          >
            <div className="center">
              <div className="mdnice-homepage-heading-introduction">
                <h1>墨滴软件</h1>
                <span>Markdown 编辑器 | 微信排版工具 | 自媒体运营插件</span>
                <div className="mdnice-homepage-pc-btn">
                  <Button
                    type="primary"
                    className="mdnice-homepage-btn-heading"
                    onClick={() => {
                      navigate("/membership/vip")
                    }}
                  >
                    PC 端
                  </Button>
                  <Button
                    className="mdnice-homepage-btn-heading"
                    onClick={() => {
                      window.open("https://editor.mdnice.com")
                    }}
                  >
                    网页端
                  </Button>
                  <Button
                    className="mdnice-homepage-btn-heading"
                    onClick={() => {
                      navigate("/membership/vip")
                    }}
                  >
                    浏览器插件
                  </Button>
                </div>
                <div className="mdnice-homepage-mobile-btn">
                  <Button
                    type="primary"
                    className="mdnice-homepage-btn-heading"
                    onClick={() => {
                      navigate("/membership/vip")
                    }}
                  >
                    相关产品
                  </Button>
                  <Button
                    className="mdnice-homepage-btn-heading"
                    onClick={() => {
                      navigate("/themes")
                    }}
                  >
                    主题查看
                  </Button>
                </div>
              </div>
            </div>
          </main>
          <div className="cd-transition-layer">
            <div className="bg-layer"></div>
          </div>
        </div>

        <div className="mdnice-homepage-carousel-container">
          <h2 className="mdnice-homepage-carousel-title">系统强大</h2>
          <p className="mdnice-homepage-carousel-explain">
            Markdown 为基础书写的文章，结构清晰，结合 Markdown Nice
            编辑器进行主题渲染，完美兼容微信公众号平台
          </p>
          <div className="mdnice-homepage-carousel">
            <Carousel
              autoplay
              dots={{ className: "mdnice-homepage-carousel-dots" }}
            >
              <div>
                <img src="https://files.mdnice.com/pic/1f270e06-2528-4b52-8e8f-2740d12e6c83.png" />
              </div>
              <div>
                <img src="https://files.mdnice.com/pic/492dd195-ae86-4028-8108-2fe3ebc10be1.png" />
              </div>
              <div>
                <img src="https://files.mdnice.com/pic/a439e94e-0c72-4c28-b1ed-029ecd373cff.png" />
              </div>
            </Carousel>
          </div>
        </div>

        <div className="mdnice-homepage-introduction-container">
          <h2 className="mdnice-homepage-carousel-title">排版方便</h2>

          <div className="introduction">
            <div className="introduction-1">
              <div className="introduction-content">
                <h3>一键排版</h3>
                <ul>
                  <li>
                    写好 Markdown 语法后无需额外排版操作，直接拷贝粘贴即可
                  </li>
                  <li>全网唯一支持数学公式发布到微信公众号的编辑器</li>
                  <li>提供mdnice图床，长期保存图片不丢失</li>
                  <li>文章编写不丢失，实时进行本地和云端存储</li>
                </ul>
              </div>
              <div className="introduction-video">
                <video
                  autoPlay
                  muted
                  preload="auto"
                  loop
                  src="https://files.mdnice.com/pic/1dda1148-10a0-4a45-8a8c-e943ecf4ec18.mov"
                />
              </div>
              <div className="introduction-img">
                <img src="https://files.mdnice.com/pic/ffcb9437-9243-41e2-83dd-d863d704d05a.png" />
              </div>
            </div>
          </div>
          <div className="introduction">
            <div className="introduction-2">
              <div className="introduction-video">
                <video
                  autoPlay
                  muted
                  preload="auto"
                  loop
                  src="https://files.mdnice.com/pic/a54260d8-186c-4ef2-a953-79af0ebf42ef.mov"
                />
              </div>
              <div className="introduction-img">
                <img src="https://files.mdnice.com/pic/fc258432-dda2-46a2-a9aa-50dab5394798.png" />
              </div>
              <div className="introduction-content">
                <h3>丰富主题</h3>
                <ul>
                  <li>支持多种主题订阅和兑换，排版文章无压力</li>
                  <li>现有主题无法满足需求可进行定制化操作</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mdnice-homepage-carousel-container">
          <h2>他们都在用</h2>
          <p className="mdnice-homepage-carousel-explain">
            每一位自媒体人都值得尊敬
          </p>
        </div> */}
        <div className="mdnice-homepage-media-container">
          <h2>媒体报道</h2>
          <p className="mdnice-homepage-media-explain">
            排版 100 万篇文章，为 1 万公众号主提升排版效率
          </p>
          {MEDIA_INTRO.map(item => (
            <div key={item.name} className="mdnice-homepage-media-item">
              <div className="mdnice-homepage-media-content">
                <h3 className="mdnice-homepage-media-wechat-name">
                  {item.name}
                </h3>
                <p className="mdnice-homepage-media-wechat-description">
                  {item.description}
                </p>
                <a
                  className="mdnice-homepage-media-wechat-link"
                  target="_blank"
                  href={item.link}
                >
                  查看原文
                </a>
              </div>
              <div className="mdnice-homepage-media-avatar">
                <Avatar
                  size={{ xs: 64, sm: 64, md: 80, lg: 80, xl: 80, xxl: 80 }}
                  src={item.avatar}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}
